import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from './Footer';
import bgImage from '../video/background.mp4'


export default function DetailsRea (){
    return(
        <div>
             <Navbar/>
             <div className="container">
                    <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li class="breadcrumb-item"><Link to='/Nos--realisations'>Nos réalisations</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Cité des orchidées</li>
                    </ol>
                    </nav>
             </div>
             <div className="project--name">
                        <h2 className="title">Cité des orchidées</h2>
                        <p>Abidjan,Cote d'Ivoire</p>
                    </div>
             {/* <div className="container"> */}
             <div className="real_dis">
             <video autoPlay loop muted>
                        <source src={bgImage}/>
                    </video>
             </div>
                <div className="main--part--detail--realisations">
                    <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/40.jpg`} alt=''/>
                    </div>
                    <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/41.jpg`} alt=''/>
                    </div>
                    <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/42.jpg`} alt=''/>
                    </div>
                    <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/43.jpg`} alt=''/>
                    </div>
                    <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/44.jpg`} alt=''/>
                    </div>
                    {/* <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/IMG_5768.jpg`} alt=''/>
                    </div> */}
                    {/* <div className="detail--cards">
                        <img src={`${process.env.PUBLIC_URL}/IMG_5768.jpg`} alt=''/>
                    </div> */}
               
                </div>
            <Footer/>
             </div>
        // </div>
    )
}